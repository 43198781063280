@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    /* HIDE SPIN COUNTER UP / DOWN in <input type number /> */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {


  .debug-icon {
    @apply bg-red-300/50 w-4 h-4 rounded-md;
  }

  .debug-element {
    @apply border border-solid border-red-600;
  }

  .bx-container {
    @apply px-6 py-6 md:py-4 max-w-[1280px] mx-auto;
  }

  .btn-comfortable {
    @apply lg:min-w-[230px] lg:min-h-[48px];
  }


}

@layer utilities {
  .shadow-custom {
    box-shadow: 0px 1px 3px 0px rgba(12, 17, 29, 0.1);
  }

  #toggle_nav:checked~div #hamburger #line {
    @apply rotate-45 translate-y-1;
  }

  #toggle_nav:checked~div #hamburger #line2 {
    @apply -rotate-45 -translate-y-0.5;
  }

  #toggle_nav:checked~div #hamburger #line3 {
    @apply hidden;
  }

  .outlined-transparent-text {
    
    text-shadow: 2px 2px 0 white, 2px -2px 0 white, -2px 2px 0 white, -2px -2px 0 white, 2px 0px 0 white, 0px 2px 0 white, -2px 0px 0 white, 0px -2px 0 white;
  }
}

/* RESPONSIVE MOBILE KARIR BTN APPLY*/
@media screen and (max-width: 531px) {
  .card-apply {
    flex-direction: column;
    align-items: flex-start;
  }
  .custom-apply {
    justify-content: flex-end; 
    margin-top: 16px;
    width: 100%;
  }
}

/* RESPONSIVE MOBILE MENU TECHNOLOGY NAV*/
@media screen and (max-width: 547px) {
  .menu-custom {
    margin: 16px;
    max-width: 360px;
    height: 94px;
  }
  .title-menu-custom {
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}